$img-w: 2250px;
$img-h: 360px;

$img-w2: 1125px;
$img-h2: 180px;

$shrink: 1;
$ratio: 1 / $shrink;
$ratio2: .5 / $shrink;
$speed: 20s;

.slider {
  // border-bottom: 3px solid #70edfe;
  // border-top: 3px solid #70edfe;

  width: 100%;
  top: calc(50% - #{$img-h * $ratio / 2});
  overflow: hidden;



  .slider-row {
    width: 100vw;
    height: $img-h * $ratio;
    background-image: url('../../images/gallery2.png');
    background-size: $img-w * $ratio $img-h * $ratio;
    animation: slide $speed linear infinite;    
  
    @media screen and (max-width: 676px) {
      background-size: $img-w * $ratio2 $img-h * $ratio2;
      height: 180px;
      width: 1125px;
      margin-top: 20px;
    }
  }
}

.slider2 {
 
  // border-bottom: 3px solid #70edfe;
  // border-top: 3px solid #70edfe;
  width: 100%;
  top: calc(50% - #{$img-h * $ratio / 2});
  overflow: hidden;
  margin-top: 40px;
  margin-bottom: 100px;

  .slider-row2 {
    width: 100vw;
    height: $img-h * $ratio;
    background-image: url('../../images/gallery1.png');
    background-size: $img-w * $ratio $img-h * $ratio;
    animation: slide2 $speed linear infinite;    
    @media screen and (max-width: 676px) {
      
      background-size: $img-w * $ratio2 $img-h * $ratio2;
      height: 180px;
      width: 1125px;
      margin-top: 25px;
    }
  
  }
}

@keyframes slide {
  from { background-position-x: 0; }
  to   { background-position-x: -$img-w * $ratio; }
}
@keyframes slideMobile {
  from { background-position-x: 0; }
  to   { background-position-x: -$img-w  * $ratio2;}
}

@keyframes slide2 {
  from { background-position-x: 0; }
  to   { background-position-x: $img-w * $ratio; }
}

@keyframes slide2Mobile {
  from { background-position-x: 0; }
  to   { background-position-x: $img-w * $ratio2; }
}