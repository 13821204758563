// Hexagon
$sqrt3: 1.7320508075688772;
$edgeSize: 45px;
$hexWidth: $edgeSize * 2;
$hexHeight: $sqrt3 * $edgeSize;

// Display
$borderHover: 18px;
$soft-white: #505050;

// Easing
$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$ease-out-quart: cubic-bezier(0.165, 0.84, 0.44, 1);



.button3 {
  transform: translate3d(-50%,-50%,0) rotate(30deg);
}
/* End of container */

// Contains 2 hexagons (.hex)
.button3 {
  cursor: pointer;
  width: $hexWidth/2;
  height: $hexHeight;
  
  margin-top: -40px;


  
  &:last-child {
    margin-right: 0;
  }

  // Hover state
  &:hover {
    .hex3:last-child {
      opacity: 1;
      transform: scale(1.3);
    }
    .hex3:first-child {
      opacity: 1;
      
      div:before, div:after {
        height: $borderHover;
      }
      
      transform: scale(1.2);
    }
  }

  
}

// Contains 3 div that makes the hexagon
.hex3 {


  left: 0px;
  width: $edgeSize;
  height: $hexHeight;

  
  // Inset
  &:first-child {
    transform: scale(0.9);
    transition: all 0.3s $ease-out-quart;
    z-index: 0;
  }
  
  // Outset
  &:last-child {
    transition: all 0.3s $ease-out-expo;
    z-index: 1;
  }
  
  div {
    box-sizing: border-box;
    position: absolute;
 
    width: $edgeSize;
    height: $hexHeight;
    
    &:before, &:after {
      content: ' ';
      position: absolute;
      background: $soft-white;
      width: 100%;
      height: 10px;
      
      transition: height 0.3s $ease-out-quart;
    }
    &:before {
      top: 0;
    }
    &:after {
      bottom: 0;
    }
    &:nth-child(1) {
      transform: rotate(0deg);
    }
    &:nth-child(2) {
      transform: rotate(60deg);
    }
    &:nth-child(3) {
      transform: rotate(120deg);
    }
    
    transform-origin: center center;
  }
}

.selected3 {


    left: 0px;
    width: $edgeSize;
    height: $hexHeight;
  
    
    // Inset
    &:first-child {
      transform: scale(0.9);
      transition: all 0.3s $ease-out-quart;
      z-index: 0;
    }
    
    // Outset
    &:last-child {
      transition: all 0.3s $ease-out-expo;
      z-index: 1;
    }
    
    div {
      box-sizing: border-box;
      position: absolute;
   
      width: $edgeSize;
      height: $hexHeight;
      
      &:before, &:after {
        content: ' ';
        position: absolute;
        background: #70edfe;
        width: 100%;
        height: 10px;
        
        transition: height 0.3s $ease-out-quart;
      }
      &:before {
        top: 0;
      }
      &:after {
        bottom: 0;
      }
      &:nth-child(1) {
        transform: rotate(0deg);
      }
      &:nth-child(2) {
        transform: rotate(60deg);
      }
      &:nth-child(3) {
        transform: rotate(120deg);
      }
      
      transform-origin: center center;
    }
  }