* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: 'Encode Sans', sans-serif;
}

@font-face {
  font-family: "Custom";   /*Can be any text*/
  src: local("Kiona-Regular"),
    /* url("./fonts/CevicheOne-Regular.ttf") format("truetype"); */
    url("./fonts/Kiona-Regular.ttf") format("truetype");
}



@font-face {
  font-family: "Content";   /*Can be any text*/
  src: local("Kiona-Regular"),
  /* url("./fonts/CevicheOne-Regular.ttf") format("truetype"); */
  url("./fonts/Kiona-Regular.ttf") format("truetype");
}


html {
  /* body will set it's height based on its parent, which is html */
  height: 100%;

  /* set full width as well */
  width: 100%;
}


body {
  /* min-height is needed for pages that might scroll, ie they may contain _more_ than 100% of viewport height */
  min-height: 100%;
  overflow-x: hidden;
  /* needed to prevent unwanted scroll-bars */
  margin: 0;
  padding: 0;
  
  /* This is just so we can tell the body block apart from the app container */
  background-color: gray;
}

#tsparticles {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
}
